const JSZip = require('jszip');
const baudrates = document.getElementById("baudrates") as HTMLSelectElement;
const connectButton = document.getElementById("connectButton") as HTMLButtonElement;
const traceButton = document.getElementById("copyTraceButton") as HTMLButtonElement;
const disconnectButton = document.getElementById("disconnectButton") as HTMLButtonElement;
const resetButton = document.getElementById("resetButton") as HTMLButtonElement;
const consoleStartButton = document.getElementById("consoleStartButton") as HTMLButtonElement;
const consoleStopButton = document.getElementById("consoleStopButton") as HTMLButtonElement;
const eraseButton = document.getElementById("eraseButton") as HTMLButtonElement;
const addFileButton = document.getElementById("addFile") as HTMLButtonElement;
const programButton = document.getElementById("programButton");
const filesDiv = document.getElementById("files");
const terminal = document.getElementById("terminal");
const programDiv = document.getElementById("program");
const consoleDiv = document.getElementById("console");
const lblBaudrate = document.getElementById("lblBaudrate");
const lblConsoleFor = document.getElementById("lblConsoleFor");
const lblConnTo = document.getElementById("lblConnTo");
const table = document.getElementById("fileTable") as HTMLTableElement;
const alertDiv = document.getElementById("alertDiv");

// This is a frontend example of Esptool-JS using local bundle file
// To optimize use a CDN hosted version like
// https://unpkg.com/esptool-js@0.2.0/bundle.js
import { ESPLoader, FlashOptions, LoaderOptions, Transport } from "../../../lib";

declare let Terminal; // Terminal is imported in HTML script
declare let CryptoJS; // CryptoJS is imported in HTML script

const term = new Terminal({ cols: 80, rows: 20 });
term.open(terminal);

let device = null;
let transport: Transport;
let chip: string = null;
let esploader: ESPLoader;
let fileArray = [];
let fileArrayb = [];
let filetype;

disconnectButton.style.display = "none";
traceButton.style.display = "none";
eraseButton.style.display = "none";
consoleStopButton.style.display = "none";
filesDiv.style.display = "none";

/**
 * The built in Event object.
 * @external Event
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Event}
 */

/**
 * File reader handler to read given local file.
 * @param {Event} evt File Select event
 */
function handleFileSelect(evt) {
  const file = evt.target.files[0];
  const files = evt.target.files;
  const zip = new JSZip();
  console.log("file type", file.type);
  filetype = "file";
  if (!file) return;
  if (file.type == "application/x-zip-compressed") {
    filetype = "zip";
    zip.loadAsync(file).then((zip) => {
      console.log("files zip", zip.files);
      if (zip.files["build/flash_args"]) {
        zip.files["build/flash_args"].async("string").then(function (data) {
          //console.log("info flash args", data);
          const lines = data.split("\n");
          const tableBody = document.getElementById('tableBodyx');
          tableBody.innerHTML = '';
          for (let i = 1; i < lines.length; i++) {
            const lineMatch = lines[i].match(/^(0x[0-9a-fA-F]+)\s+(\S+)/);

            if (lineMatch) {
              const offsets = lineMatch[1];
              const offset = parseInt(offsets); // parse the offset as hexadecimal
              const fileName = lineMatch[2];
              console.log("offset", offset);
              console.log("filename", fileName);
              const fileRow = document.createElement('tr');
              const offsetCell = document.createElement('td');
              const fileNameCell = document.createElement('td');
              offsetCell.textContent = offset.toString();
              fileNameCell.textContent = fileName;
              fileRow.appendChild(offsetCell);
              fileRow.appendChild(fileNameCell);
              tableBody.appendChild(fileRow);
              zip.file(`build/${fileName}`).async("arraybuffer").then((content) => {
                const binData = Buffer.from(content); // convert the array buffer to a Node.js Buffer
                //console.log("bin data", binData);

                // Convert the binary data to a string format
                const binString = binData.toString("latin1");
                //console.log("bin string", binString);

                fileArrayb.push({ data: binString, address: offset });
                // Process the binary data as needed
              });
            }
          }
        });

      } else {

        filetype = "zip";
        let manifestFile;

        for (const fileName in zip.files) {
          if (fileName.endsWith("/manifest.json")) {
            manifestFile = zip.files[fileName];

            console.log("manifestFile", manifestFile);
            break;
          }
        }

        if (manifestFile) {
          zip.files[manifestFile.name].async("string").then(function (data) {
            const splitPath = manifestFile.name.split('/');
            const main = splitPath.slice(0, -1).join('/');
            console.log(main);
            const manifest = JSON.parse(data);
            const tableBody = document.getElementById('tableBodyx');
            if (manifest.parts) {
              // Iteration over parts "objects"
              for (const partName in manifest.parts) {
                if (manifest.parts.hasOwnProperty(partName)) {
                  const part = manifest.parts[partName];
                  // get file name
                  const offset = part.addr;
                  const filename = part.src;
                  console.log("file", filename);

                  if (filename && filename.endsWith('.bin')) {
                    // get the address and offset
                    console.log("offset:", offset);
                    console.log("filename:", filename);
                    const fileRow = document.createElement('tr');
                    const offsetCell = document.createElement('td');
                    const fileNameCell = document.createElement('td');
                    offsetCell.textContent = offset.toString();
                    fileNameCell.textContent = filename;
                    fileRow.appendChild(offsetCell);
                    fileRow.appendChild(fileNameCell);
                    tableBody.appendChild(fileRow);

                    zip.file(`${main}/${filename}`).async("arraybuffer").then((content) => {
                      const binData = Buffer.from(content); // convert the array buffer to a Node.js Buffer

                      // Convert the binary data to a string format
                      const binString = binData.toString("latin1");

                      fileArrayb.push({ data: binString, address: offset });
                      // Process the binary data as needed
                    });
                  }
                }
              }
            }

          });


        }

      }

    });
  }

  if (files.length > 1) {
    console.log("print file", files);
    const tableBody = document.getElementById('tableBodyx');
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filename = file.name;
      console.log("file", filename);
      console.log("file type", file.type );

      if (file.type == "application/octet-stream") {
        // get the address and offset
        console.log("filename:", filename);
        const fileRow = document.createElement('tr');
        const offsetCell = document.createElement('td');
        const fileNameCell = document.createElement('td');

        // Create an editable input for offset
        const offsetInput = document.createElement('input');
        offsetInput.type = 'text';
        offsetInput.value = ""; // Set default value to offset
        offsetInput.addEventListener('change', () => {
          // Update offset value in your data structure when user changes it
          file.offset = offsetInput.value;
        });

        offsetCell.appendChild(offsetInput);
        fileNameCell.textContent = filename;
        fileRow.appendChild(offsetCell);
        fileRow.appendChild(fileNameCell);
        tableBody.appendChild(fileRow);


        const reader = new FileReader();

        reader.onload = async (ev: ProgressEvent<FileReader>) => {

          evt.target.data = ev.target.result;
          //  console.log("aqui data", data);

        };
        reader.readAsBinaryString(file);

      }

    }

    return;

  }
  const reader = new FileReader();

  reader.onload = async (ev: ProgressEvent<FileReader>) => {
    const data = ev.target.result;
    evt.target.data = ev.target.result;
    //  console.log("aqui data", data);

  };

  reader.readAsBinaryString(file);
}


const espLoaderTerminal = {
  clean() {
    term.clear();
  },
  writeLine(data) {
    term.writeln(data);
  },
  write(data) {
    term.write(data);
  },
};

connectButton.onclick = async () => {
  if (device === null) {
    device = await navigator.serial.requestPort({});
    transport = new Transport(device, true);
  }

  try {
    const flashOptions = {
      transport,
      baudrate: parseInt(baudrates.value),
      terminal: espLoaderTerminal,
    } as LoaderOptions;
    esploader = new ESPLoader(flashOptions);

    chip = await esploader.main();

    // Temporarily broken
    // await esploader.flashId();
  } catch (e) {
    console.error(e);
    term.writeln(`Error: ${e.message}`);
  }

  console.log("Settings done for :" + chip);
  lblBaudrate.style.display = "none";
  lblConnTo.innerHTML = "Connected to device: " + chip;
  lblConnTo.style.display = "block";
  baudrates.style.display = "none";
  connectButton.style.display = "none";
  disconnectButton.style.display = "initial";
  traceButton.style.display = "initial";
  eraseButton.style.display = "initial";
  filesDiv.style.display = "initial";
  consoleDiv.style.display = "none";
};

traceButton.onclick = async () => {
  if (transport) {
    transport.returnTrace();
  }
};

resetButton.onclick = async () => {
  if (device === null) {
    device = await navigator.serial.requestPort({});
    transport = new Transport(device, true);
  }

  await transport.setDTR(false);
  await new Promise((resolve) => setTimeout(resolve, 100));
  await transport.setDTR(true);
};

eraseButton.onclick = async () => {
  eraseButton.disabled = true;
  try {
    await esploader.eraseFlash();
  } catch (e) {
    console.error(e);
    term.writeln(`Error: ${e.message}`);
  } finally {
    eraseButton.disabled = false;
  }
};

addFileButton.onclick = () => {
  const rowCount = table.rows.length;
  const row = table.insertRow(rowCount);

  //Column 1 - Offset
  const cell1 = row.insertCell(0);
  const element1 = document.createElement("input");
  element1.type = "text";
  element1.id = "offset" + rowCount;
  element1.value = "0x1000";
  cell1.appendChild(element1);


  // Column 2 - File selector
  const cell2 = row.insertCell(1);
  const element2 = document.createElement("input");
  element2.type = "file";
  element2.id = "selectFile" + rowCount;
  element2.name = "selected_File" + rowCount;
  //  element2.webkitdirectory = true; // Allow directory selection
  element2.multiple = true; // Allow multiple file selection
  element2.addEventListener("change", handleFileSelect, false);
  cell2.appendChild(element2);

  // Column 3  - Progress
  const cell3 = row.insertCell(2);
  cell3.classList.add("progress-cell");
  cell3.style.display = "none";
  cell3.innerHTML = `<progress value="0" max="100"></progress>`;

  // Column 4  - Remove File
  const cell4 = row.insertCell(3);
  cell4.classList.add("action-cell");
  if (rowCount > 1) {
    const element4 = document.createElement("input");
    element4.type = "button";
    const btnName = "button" + rowCount;
    element4.name = btnName;
    element4.setAttribute("class", "btn");
    element4.setAttribute("value", "Remove"); // or element1.value = "button";
    element4.onclick = function () {
      removeRow(row);
    };
    cell4.appendChild(element4);
  }
};

/**
 * The built in HTMLTableRowElement object.
 * @external HTMLTableRowElement
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLTableRowElement}
 */

/**
 * Remove file row from HTML Table
 * @param {HTMLTableRowElement} row Table row element to remove
 */
function removeRow(row: HTMLTableRowElement) {
  const rowIndex = Array.from(table.rows).indexOf(row);
  table.deleteRow(rowIndex);
}

/**
 * Clean devices variables on chip disconnect. Remove stale references if any.
 */
function cleanUp() {
  device = null;
  transport = null;
  chip = null;
}

disconnectButton.onclick = async () => {
  if (transport) await transport.disconnect();

  term.clear();
  baudrates.style.display = "initial";
  connectButton.style.display = "initial";
  disconnectButton.style.display = "none";
  traceButton.style.display = "none";
  eraseButton.style.display = "none";
  lblConnTo.style.display = "none";
  filesDiv.style.display = "none";
  alertDiv.style.display = "none";
  consoleDiv.style.display = "initial";
  cleanUp();
};

let isConsoleClosed = false;
consoleStartButton.onclick = async () => {
  if (device === null) {
    device = await navigator.serial.requestPort({});
    transport = new Transport(device, true);
  }
  lblConsoleFor.style.display = "block";
  consoleStartButton.style.display = "none";
  consoleStopButton.style.display = "initial";
  programDiv.style.display = "none";

  await transport.connect();
  isConsoleClosed = false;

  while (true && !isConsoleClosed) {
    const val = await transport.rawRead();
    if (typeof val !== "undefined") {
      term.write(val);
    } else {
      break;
    }
  }
  console.log("quitting console");
};

consoleStopButton.onclick = async () => {
  isConsoleClosed = true;
  await transport.disconnect();
  await transport.waitForUnlock(1500);
  term.clear();
  consoleStartButton.style.display = "initial";
  consoleStopButton.style.display = "none";
  programDiv.style.display = "initial";
};

/**
 * Validate the provided files images and offset to see if they're valid.
 * @returns {string} Program input validation result
 */
function validateProgramInputs() {
  const offsetArr = [];
  const rowCount = table.rows.length;
  let row;
  let offset = 0;
  let fileData = null;

  // check for mandatory fields
  if (filetype = "zip") {
    for (let index = 1; index < rowCount; index++) {
      row = table.rows[index];

      //offset fields checks
      const offSetObj = row.cells[0].childNodes[0];
      offset = parseInt(offSetObj.value);

      // Non-numeric or blank offset
      //if (Number.isNaN(offset)) return "Offset field in row " + index + " is not a valid address!";
      // Repeated offset used
      //else if (offsetArr.includes(offset)) return "Offset field in row " + index + " is already in use!";
      //else offsetArr.push(offset);

      const fileObj = row.cells[1].childNodes[0];
      fileData = fileObj.data;
      if (fileData == null) return "No file selected for row " + index + "!";
    }
  } else {
    // check for mandatory fields
    for (let index = 1; index < rowCount; index++) {
      row = table.rows[index];

      //offset fields checks
      const offSetObj = row.cells[0].childNodes[0];
      offset = parseInt(offSetObj.value);

      // Non-numeric or blank offset
      if (Number.isNaN(offset)) return "Offset field in row " + index + " is not a valid address!";
      // Repeated offset used
      else if (offsetArr.includes(offset)) return "Offset field in row " + index + " is already in use!";
      else offsetArr.push(offset);

      const fileObj = row.cells[1].childNodes[0];
      fileData = fileObj.data;
      if (fileData == null) return "No file selected for row " + index + "!";
    }

  }
  return "success";
}

programButton.onclick = async () => {
  const alertMsg = document.getElementById("alertmsg");
  const err = validateProgramInputs();

  if (err != "success") {
    alertMsg.innerHTML = "<strong>" + err + "</strong>";
    alertDiv.style.display = "block";
    return;
  }

  // Hide error message
  alertDiv.style.display = "none";

  const fileArray = [];
  const progressBars = [];

  if (filetype = "file") {
    for (let index = 1; index < table.rows.length; index++) {
      const row = table.rows[index];

      const offSetObj = row.cells[0].childNodes[0] as HTMLInputElement;
      const offset = parseInt(offSetObj.value);

      const fileObj = row.cells[1].childNodes[0] as ChildNode & { data: string };
      const progressBar = row.cells[2].childNodes[0];

      progressBar.textContent = "0";
      progressBars.push(progressBar);

      row.cells[2].style.display = "initial";
      row.cells[3].style.display = "none";

      fileArray.push({ data: fileObj.data, address: offset });
    }

    fileArrayb = fileArray;
    console.log("file", fileArray);
  }

  try {
    const flashOptions: FlashOptions = {
      fileArray: fileArrayb,
      flashSize: "keep",
      eraseAll: false,
      compress: true,
      calculateMD5Hash: (image) => CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
    } as FlashOptions;
    await esploader.writeFlash(flashOptions);
  } catch (e) {
    console.error(e);
    term.writeln(`Error: ${e.message}`);
  } finally {
    // Hide progress bars and show erase buttons
    for (let index = 1; index < table.rows.length; index++) {
      table.rows[index].cells[2].style.display = "none";
      table.rows[index].cells[3].style.display = "initial";
    }
  }
};

addFileButton.onclick(this);
